import React from 'react';
import Link from 'gatsby-link';

const Menu = props => (
  <nav id="menu">
    <div className="inner">
      <ul className="links">
        <li>
          <Link onClick={props.onToggleMenu} to="/">
            Home
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/media">
            Media
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/songs">
            Songs
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/acoustic-duo">
            Acoustic Duo
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/reviews">
            Reviews
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/frequently-asked-questions">
            FAQ
          </Link>
        </li>
      </ul>
      <ul className="actions vertical">
        <li>
          <Link className="button special fit" onClick={props.onToggleMenu} to="#contact">
            Contact Us
          </Link>
        </li>
      </ul>
    </div>
    <a className="close" onClick={props.onToggleMenu} href="javascript:;">
      Close
    </a>
  </nav>
);

export default Menu;
