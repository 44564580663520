import React from 'react';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby-link';
import '../assets/scss/main.scss';
import Header from './Header';
import Menu from './Menu';
import Footer from './Footer';
// import socialBanner from '../assets/images/audio-vultures-share.jpg'

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuVisible: false,
      loading: 'is-loading',
    };
    this.handleToggleMenu = this.handleToggleMenu.bind(this);
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ loading: '' });
    }, 100);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  handleToggleMenu() {
    this.setState({
      isMenuVisible: !this.state.isMenuVisible,
    });
  }

  render() {
    const { children } = this.props;

    return (
      <div
        className={`body ${this.state.loading} ${
          this.state.isMenuVisible ? 'is-menu-visible' : ''
        }`}>
        <Helmet>
          <link rel="stylesheet" href={withPrefix('skel.css')} />
          <meta
            property="og:image"
            content="https://www.audiovultures.co.uk/audio-vultures-share.jpg"
          />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Wedding Band Bristol - Audio Vultures" />
          <meta
            property="og:description"
            content="Audio Vultures are the premier wedding band in Bristol, providing party and wedding band services!"
          />
          <meta property="og:url" content="https://www.audiovultures.co.uk" />
          <script src="/clarity.js" type="text/javascript" />
        </Helmet>
        <div id="wrapper">
          <Header onToggleMenu={this.handleToggleMenu} />
          {children}
          <Footer />
        </div>
        <Menu onToggleMenu={this.handleToggleMenu} onToggleMenuClose={this.handleToggleMenuClose} />
      </div>
    );
  }
}

export default Layout;
