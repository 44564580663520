import React from 'react';
import qrCode from '../assets/images/qr.jpg';
import 'react-datepicker/dist/react-datepicker.css';

class Contact extends React.Component {
  state = {
    startDate: undefined,
  };

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  handleRecaptcha = (value) => {
    this.setState({ 'g-recaptcha-response': value });
  };

  render() {
    return (
      <section id="contact">
        <div className="inner">
          <div className="contact-method">
            <h3>Facebook Message</h3>

            <a href="http://m.me/audiovultures">Message us via Facebook</a>
          </div>

          <div className="contact-method">
            <h3>Call or Text</h3>
            <span>
              <a href="tel:+447999423823">07999 423 823</a>
            </span>
          </div>

          <div className="contact-method">
            <h3>vCard QR Code</h3>
            <span>
              <img src={qrCode} className="qr" alt="Audio Vultures QR Code" />
            </span>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact;
