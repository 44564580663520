import React from 'react';
import Contact from './Contact';

const d = new Date();
const currentYear = d.getFullYear();

const Footer = () => (
  <footer id="footer">
    <div className="inner">
      <Contact />

      <ul className="icons">
        <li>
          <a
            href="https://en-gb.facebook.com/audiovultures"
            target="_blank"
            rel="noreferrer"
            className="icon alt fa-facebook">
            <span className="label">Facebook</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/audiovultures"
            target="_blank"
            rel="noreferrer"
            className="icon alt fa-instagram">
            <span className="label">Instagram</span>
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/audiovultures"
            target="_blank"
            rel="noreferrer"
            className="icon alt fa-twitter">
            <span className="label">Twitter</span>
          </a>
        </li>
        <li>
          <a
            href="https://soundcloud.com/audiovultures"
            target="_blank"
            rel="noreferrer"
            className="icon alt fa-soundcloud">
            <span className="label">Soundcloud</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/channel/UCQ-r1kD1Bpyh5dR8pDc-Faw/featured"
            target="_blank"
            rel="noreferrer"
            className="icon alt fa-youtube">
            <span className="label">Youtube</span>
          </a>
        </li>
      </ul>
      <ul className="copyright">
        <li>&copy; {currentYear} Audio Vultures</li>
        <li>
          Development: <a href="http://www.stephenkempin.co.uk/">S Kempin</a>
        </li>
      </ul>
    </div>
  </footer>
);

export default Footer;
